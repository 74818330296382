import { render, staticRenderFns } from "./materials.vue?vue&type=template&id=26f05d39&scoped=true"
import script from "./materials.vue?vue&type=script&lang=js"
export * from "./materials.vue?vue&type=script&lang=js"
import style0 from "./materials.vue?vue&type=style&index=0&id=26f05d39&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f05d39",
  null
  
)

export default component.exports